const AlgoliaConstants = {
  hitsPerPage: 100
};

const PageIdentifiers = {
  CompanyProfile: 'companyProfile',
  ParticipantProfile: 'participantProfile',
  CompanyDetail: 'companyDetails',
  ParticipantDetail: 'participantDetails',
  CompanyDirectory: 'companyDirectory',
  ParticipantDirectory: 'participantDirectory'
};

const RegRedirectConstants = {
  REG_REDIRECT_URL: '/reg-redirect',
  REG_REDIRECT_URL_DEV: 'https://showcase-ui-dev.css.rxweb-dev.com/component/regRedirectComponent',
  PRODUCT_TYPE: 'productType',
  ATLAS20SHOW: 'Atlas2.0',
  LOGIN_EMAIL_PATH: 'secure/Account/LoginEmail',
  REDIRECT_URL: 'redirectUrl',
  PKCE_REDIRECT_URL_DEV: 'https://showcase-ui-dev.css.rxweb-dev.com/component/pkceSignupRedirectComponent',
  PKCE_SIGNUP_REDIRECT_URL: '/pkce-signup',
  RxAtlasEe: 'rxAtlasEe'
};

const Claims = {
  RxParticipant: 'rxParticipant',
  RxOrg: 'rxOrg',
  RxAtlasEe: 'rxAtlasEe'
};

const CountryFilters = 'Country';

const MaximumFeatureFilterItems = 20;

const PPSSortOrder = 'sortOrder';

const Devices = {
  desktop: 'desktop',
  mobile: 'mobile'
};

const Modes = {
  private: 'private',
  protected: 'protected',
  public: 'public'
};

const FieldValidationErrorCodes = {
  invalidFileSize: 'invalid_file_size',
  invalidFileNameSize: 'invalid_file_name_size',
  invalidFileFormat: 'invalid_file_format'
};

const MaxLength = {
  description: 600,
  participant: {
    firstName: 120,
    middleName: 120,
    lastName: 120,
    jobTitle: 255,
    companyName: 60,
    phone: 25
  },
  features: 25
};

const ImageFileExtensions = ['jpeg', 'jpg', 'gif', 'png'];
const MaxMobileScreenWidth = 420;
const MaxFileNameSize = 255;

const oneKibibyte = 1024;
const oneMebibyte = 1024 * oneKibibyte;
const fiveMebibyte = 5 * oneMebibyte;
const itemsPerPage = 100;

const SocialMediaKeys = [
  'TWITTER',
  'LINKEDIN'
  // 'YOUTUBE',
  // 'FACEBOOK',
  // 'WECHAT'
];

const SocialMediaConstants = {
  facebookPath: 'M36.58,34.62a1.73,1.73,0,0,1-.49,1.23,1.66,1.66,0,0,1-1.26.' +
  '53h-8V24.14h4.08l.63-4.71H26.81v-3a2.63,2.63,' +
  '0,0,1,.42-1.69,2.46,2.46,0,0,1,1.9-.63h2.53V9.8c-1-.' +
  '1-2.2-.14-3.65-.14a6.15,6.15,0,0,0-4.43,1.61,6.12,6.12,0,0,0-1.69,' +
  '4.64v3.52H17.81v4.71h4.08V36.38h-15a1.65,1.65,0,0,1-1.26-.53,1.74,' +
  '1.74,0,0,1-.5-1.23v-28a1.71,1.71,0,0,1,.5-1.26,1.69,' +
  '1.69,0,0,1,1.26-.49h28a1.76,1.76,0,0,1,1.23.49,1.65,1.65,0,0,1,.52,1.26Z',
  FACEBOOK: 'M12.48,11.89a.6.6,0,0,1-.16.41.55.55,0,0,' +
  '1-.43.18H9.22V8.4h1.36l.21-1.57H9.22v-1a.9.9,0,0,1,.14-.56A.83.83,0,0,' +
  '1,10,5.05h.85V3.62c-.33,0-.74-.05-1.22-.05a2.06,2.06,0,0,' +
  '0-1.48.54,2.07,2.07,0,0,0-.56,1.55V6.83H6.22V8.4H7.58v4.08h-5a.55.55,' +
  '0,0,1-.43-.18A.56.56,0,0,1,2,11.89V2.57A.55.55,0,0,1,2.57,2h9.32a.56.56,0,' +
  '0,1,.41.16.55.55,0,0,1,.18.43Z',
  TWITTER: 'M12,5.1a6.88,6.88,0,0,1-.82,3.25A6.61,6.61,0,0,1,8.82,11,6.71,' +
  '6.71,0,0,1,5,12.1,6.78,6.78,0,0,1,1.23,11a5.15,5.15,0,0,0,.59,0A4.91,' +
  '4.91,0,0,0,4.89,10a2.32,2.32,0,0,1-1.45-.48,2.41,2.41,0,0,1-.85-1.21l.44,' +
  '0a3.7,3.7,0,0,0,.66-.07,2.56,2.56,0,0,1-1.41-.87,2.38,2.38,0,0,1-.56-1.55v0a2.3,' +
  '2.3,0,0,0,1.1.32A2.61,2.61,0,0,1,2,5.21,2.29,2.29,0,0,1,1.72,4,2.39,2.39,0,0,' +
  '1,2.07,2.8,6.86,6.86,0,0,0,4.31,4.63a6.92,6.92,0,0,0,2.83.75,2.71,2.71,0,0,1,0-.56,' +
  '2.48,2.48,0,0,1,.33-1.24,2.4,2.4,0,0,1,.89-.9,2.35,2.35,0,0,1,1.22-.33,2.18,2.18,' +
  '0,0,1,1,.22,2.55,2.55,0,0,1,.81.56,5,5,0,0,0,1.57-.59A2.46,2.46,0,0,1,11.82,3.9a5,' +
  '5,0,0,0,1.41-.4A5.43,5.43,0,0,1,12,4.79,2.05,2.05,0,0,1,12,5.1Z',
  YOUTUBE: 'M13.37,4.14a10,10,0,0,1,.23,1.92l0,' +
  '1.17,0,1.17a10.24,10.24,0,0,1-.23,1.95,1.69,' +
  '1.69,0,0,1-.42.7,1.73,1.73,0,0,1-.73.42,16.65,16.65,0,0,1-2.86.24l-2.13,' +
  '0-2.13,0a16.65,16.65,0,0,1-2.86-.24,1.65,1.65,0,0,' +
  '1-1.15-1.12A10.94,10.94,0,0,1,.85,8.4l0-1.17c0-.34,0-.73,' +
  '0-1.17a10.66,10.66,0,0,1,.24-1.92,1.62,1.62,0,0,1,.42-.73A1.67,1.67,' +
  '0,0,1,2.24,3,16.65,16.65,0,0,1,5.1,2.75l2.13,0,2.13,0A16.65,16.65,0,0,1,12.22,' +
  '3a1.73,1.73,0,0,1,.73.42A1.62,1.62,0,0,1,13.37,4.14Zm-7.45,5L9.27,7.23,5.92,5.33Z',
  LINKEDIN: 'M11.73,2a.73.73,0,0,1,.53.22.77.77,0,0,1,.22.53v9a.75.75,0,0,' +
  '1-.75.75h-9a.77.77,0,0,1-.53-.22A.73.73,0,0,1,2,11.73v-9A.75.75,0,0,1,2.73,' +
  '2ZM4.37,5.28A.89.89,0,0,0,5,3.75a.87.87,0,0,0-.64-.27.83.83,0,0,0-.63.27A.89.89,' +
  '0,0,0,3.74,5,.83.83,0,0,0,4.37,5.28ZM5.14,11h0V6H3.6v5ZM11,11V8.24a3.39,3.39,0,0,' +
  '0-.33-1.71,1.66,1.66,0,0,0-1.52-.68,1.81,1.81,0,0,0-1,.25,1.34,1.34,0,0,' +
  '0-.52.54h0V6H6.13v5H7.67V8.49a1.92,1.92,0,0,1,.17-.89.82.82,0,0,' +
  '1,.77-.39.67.67,0,0,1,.71.44,2.76,2.76,0,0,1,.11.89V11Z',
  WECHAT: 'M9.5,5.17a4,4,0,0,0-1.94.48A3.64,3.64,0,0,0,6.15,7a3.4,3.4,0,0,0-.51,' +
  '1.82,3,3,0,0,0,.14.93l-.43,0A7.48,7.48,0,0,1,4,9.57L3.76,9.5l-1.59.82.44-1.38A3.73,' +
  '3.73,0,0,1,.78,5.85,3.43,3.43,0,0,1,1.39,3.9,4.19,4.19,0,0,1,3.06,2.5,5.06,5.06,0,0,' +
  '1,5.35,2a5.27,5.27,0,0,1,2.09.42A4.7,4.7,0,0,1,9.09,3.55,3.39,3.39,0,0,1,10,5.19,3.24,' +
  '3.24,0,0,0,9.5,5.17ZM3.85,5.05a.61.61,0,0,0,.43-.15.53.53,0,0,0,.16-.41.53.53,0,0,' +
  '0-.16-.41.61.61,0,0,0-.43-.16.78.78,0,0,0-.46.16.49.49,0,0,0,0,.82A.77.77,0,0,0,3.85,' +
  '5.05Zm9.82,3.66a2.64,2.64,0,0,1-.44,1.45,4.7,4.7,0,0,1-1.15,1.17l.35,1.15-1.26-.68-.07,' +
  '0A6.13,6.13,0,0,1,9.81,12a4.29,4.29,0,0,1-2-.44,3.63,3.63,0,0,1-1.41-1.21,2.93,2.93,0,0,' +
  '1-.51-1.66,3,3,0,0,1,.51-1.67,3.61,3.61,0,0,1,1.41-1.2,4.29,4.29,0,0,1,2-.45,4.18,4.18,0,' +
  '0,1,1.9.45,3.82,3.82,0,0,1,1.43,1.2A2.87,2.87,0,0,1,13.67,8.71ZM7.07,3.92a.85.85,0,0,' +
  '0-.5.16.51.51,0,0,0,0,.82.81.81,0,0,0,.48.15.61.61,0,0,0,.43-.15.56.56,0,0,0,.15-.41.56.56,' +
  '0,0,0-.15-.41A.58.58,0,0,0,7.07,3.92ZM8.54,8.14A.55.55,0,0,0,9,8a.42.42,0,0,0,.15-.31A.42.42,' +
  '0,0,0,9,7.37a.6.6,0,0,0-.41-.14.42.42,0,0,0-.3.14.41.41,0,0,0-.14.32A.41.41,0,0,0,8.24,8,' +
  '.39.39,0,0,0,8.54,8.14Zm2.51,0A.55.55,0,0,0,11.46,8a.42.42,0,0,0,.15-.31.4.4,0,0,' +
  '0-.16-.32.61.61,0,0,0-.4-.14.46.46,0,0,0-.31.14.45.45,0,0,0-.14.32.44.44,0,0,0,' +
  '.14.31A.42.42,0,0,0,11.05,8.14Z'
};

export const FavouriteConstants = {
  sourceDirectory: 'ParticipantDirectory',
  participantFavourite: 'ParticipantFavourite',
  participantUnfavourite: 'ParticipantUnFavourite',
  favouriteIconUnSelectedPath1: 'M23.2142857,6.3984375 C23.2142857,' +
      '2.55664062 20.5636161,1.75 18.3314732,1.75 C16.2527902,1.75 13.9090402,' +
      '3.95117188 13.1835938,4.79882812 C12.8487723,5.1953125 12.1512277,' +
      '5.1953125 11.8164063,4.79882812 C11.0909598,3.95117188 8.74720982,1.75 ' +
      '6.66852679,1.75 C4.43638393,1.75 1.78571429,2.55664062 1.78571429,6.3984375 C1.78571429,' +
      '8.90039062 4.36662946,11.2246094 4.39453125,11.2519531 L12.5,18.9082031 L20.5915179,' +
      '11.265625 C20.6333705,11.2246094 23.2142857,8.90039062 23.2142857,6.3984375 Z M25,' +
      '6.3984375 C25,9.6796875 21.9308036,12.4277344 21.8052455,12.5507812 L13.1138393,20.7539062 ' +
      'C12.9464286,20.9179688 12.7232143,21 12.5,21 C12.2767857,21 12.0535714,20.9179688 ' +
      '11.8861607,20.7539062 L3.18080357,12.5234375 C3.06919643,12.4277344 0,9.6796875 0,6.3984375 ' +
      'C0,2.39257812 2.49720982,0 6.66852679,0 C9.10993304,0 11.3978795,1.88671875 12.5,2.953125 ' +
      'C13.6021205,1.88671875 15.890067,0 18.3314732,0 C22.5027902,0 25,2.39257812 25,6.3984375 Z',
  favouriteIconUnSelectedPath2: 'M23.2142857,6.3984375 C23.2142857,2.55664062 ' +
      '20.5636161,1.75 18.3314732,1.75 C16.2527902,1.75 13.9090402,3.95117188 13.1835937,' +
      '4.79882812 C12.8487723,5.1953125 12.1512277,5.1953125 11.8164062,4.79882812 ' +
      'C11.0909598,3.95117188 8.74720982,1.75 6.66852679,1.75 C4.43638393,1.75 1.78571429,' +
      '2.55664062 1.78571429,6.3984375 C1.78571429,8.90039062 4.36662946,11.2246094 4.39453125,' +
      '11.2519531 L12.5,18.9082031 L20.5915179,11.265625 C20.6333705,11.2246094 23.2142857,' +
      '8.90039062 23.2142857,6.3984375 Z',
  favouriteIconSelected: 'M1495,495.398438 C1495,498.679688 1491.9308,501.427734 ' +
      '1491.80525,501.550781 L1483.11384,509.753906 C1482.94643,509.917969 1482.72321,510 ' +
      '1482.5,510 C1482.27679,510 1482.05357,509.917969 1481.88616,509.753906 L1473.1808, ' +
      '501.523438 C1473.0692,501.427734 1470,498.679688 1470,495.398438 C1470,491.392578 ' +
      '1472.49721,489 1476.66853,489 C1479.10993,489 1481.39788,490.886719 1482.5,491.953125 ' +
      'C1483.60212,490.886719 1485.89007,489 1488.33147,489 C1492.50279,489 1495,491.392578 1495, ' +
      '495.398438 Z'
};

export const NetworkingConstants = {
  networkingIcon: 'M29,37.34375 C42.4004193,37.34375 57.9285714,43.2403415 57.9285714,52.578125 ' +
      'L57.9285714,58.671875 C57.9285714,60.3546176 56.4894867,61.71875 54.7142857,61.71875 C52.9390847,61.71875 ' +
      '51.5,60.3546176 51.5,58.671875 L51.5,52.578125 C51.5,48.1294048 39.1442838,43.4375 29,43.4375 L28.6493427,43.4393522 ' +
      'C18.5733092,43.5453278 6.5,48.1805395 6.5,52.578125 L6.5,52.578125 L6.5,58.671875 C6.5,60.3546176 5.06091527,61.71875 ' +
      '3.28571429,61.71875 C1.5105133,61.71875 0.0714285714,60.3546176 0.0714285714,58.671875 L0.0714285714,58.671875 ' +
      'L0.0714285714,52.578125 C0.0714285714,43.2403415 15.5995807,37.34375 29,37.34375 L29,37.34375 Z M29,0.78125 ' +
      'C37.8787724,0.78125 45.0714286,7.59928865 45.0714286,16.015625 C45.0714286,24.4319613 37.8787724,31.25 29,31.25 ' +
      'C20.1212276,31.25 12.9285714,24.4319613 12.9285714,16.015625 C12.9285714,7.59928865 20.1212276,0.78125 29,0.78125 ' +
      'Z M29,6.875 C23.6716296,6.875 19.3571429,10.9647738 19.3571429,16.015625 C19.3571429,21.0664762 23.6716296,25.15625 ' +
      '29,25.15625 C34.3283704,25.15625 38.6428571,21.0664762 38.6428571,16.015625 C38.6428571,10.9647738 34.3283704,6.875 ' +
      '29,6.875 Z',
  addToMyNetworkPlusIcon: 'M80.5,15 C90.1679966,15 98,22.8320034 98,32.5 C98,42.1679966 90.1679966,50 80.5,50 ' +
      'C70.8320034,50 63,42.1679966 63,32.5 C63,22.8320034 70.8320034,15 80.5,15 Z M83,20 L78,20 L78,30.038 L68,30.0384615 ' +
      'L68,34.9615385 L78,34.961 L78,45 L83,45 L83,34.961 L93,34.9615385 L93,30.0384615 L83,30.038 L83,20 Z',
  removeFromMyNetworkMinusIcon: 'M81.5,16 C91.1679966,16 99,23.8320034 99,33.5 C99,43.1679966 91.1679966,51 81.5,51 ' +
      'C71.8320034,51 64,43.1679966 64,33.5 C64,23.8320034 71.8320034,16 81.5,16 Z M94,31.0384615 L69,31.0384615 L69,35.9615385 ' +
      'L94,35.9615385 L94,31.0384615 Z'
};

const ReactSettings = {
  participantProfile: 'reactSettingsParticipantProfile',
  participantDetails: 'reactSettingsParticipantDetails',
  participantList: 'reactSettingsParticipantList',
  participantDirectory: 'reactSettingsParticipantDirectory',
  hybridAuthRedirect: 'reactSettingsHybridAuthRedirect',
  featureFilters: 'reactSettingsFeatureFilters',
  reactSettingsParticipantSettings: 'reactSettingsParticipantSettings',
  default: 'reactSettings'
};

const NetworkingFacet = {
  userId: 'userId',
  networking: 'Networking',
  participantsInMyNetwork: 'Participants in my Network'
};

const CookieName = {
  ALGOLIA_TOKEN: 'AlgoliaToken'
};

const Survey = {
  SurveyLink: 'https://reedexpo.efmfeedback.com/se/7FDA9ED25C0F44CA'
};

const MixpanelEventName = {
  ParticipantInteracted: 'Participant Interacted',
  ContactInfoInteracted: 'Contact Info Interacted',
  ViewCompany: 'View Company',
  SocialMedia: 'Social Media Clicked',
  PlanAdded: 'Plan Added',
  PlanRemoved: 'Plan Removed'
};

export {
  AlgoliaConstants,
  FieldValidationErrorCodes,
  RegRedirectConstants,
  Devices,
  oneMebibyte,
  fiveMebibyte,
  MaxFileNameSize,
  ImageFileExtensions,
  MaxMobileScreenWidth,
  itemsPerPage,
  Modes,
  MaxLength,
  SocialMediaKeys,
  SocialMediaConstants,
  PageIdentifiers,
  ReactSettings,
  CountryFilters,
  PPSSortOrder,
  NetworkingFacet,
  CookieName,
  MaximumFeatureFilterItems,
  Claims,
  Survey,
  MixpanelEventName
};
